import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import {
    useCalculationDetailsQuery,
    useLazyCalculationDetailsQuery,
} from "../../../store/api";
import { getAllFields } from "./fields-slice";
import { selectAllChoices } from "./choices-slice";
import { selectAllDates } from "./dates-slice";
import {
    makeId,
    selectAllChoicesPrices,
    selectChoicesPricesColumns,
} from "./choices-prices-slice";
import { processFlightOverride } from "./flight-override";
import { useManualReload } from "./state-slice";
import { updateManualReload } from "./state-slice";

const selectEntriesParam = createSelector(
    // no selectAllEntries here to avoid reload on reorder
    (state) => state.calculation.entries.entities,
    (entries) =>
        Object.values(entries).map(
            ({
                id,
                identifier,
                service,
                occupancyChoices,
                childChoices,
                count,
                exchangeRate,
                additionalService,
                group,
                data,
                day,
                duration,
                serviceFromDate,
                serviceToDate,
            }) => ({
                id,
                identifier,
                service,
                occupancyChoices,
                childChoices,
                count,
                exchangeRate,
                additionalService,
                group,
                data,
                day,
                duration,
                serviceFromDate,
                serviceToDate,
            })
        )
);
const selectFieldParams = createSelector(
    getAllFields,
    ({
        organization: id,
        dateRanges,
        daysCount,
        participantsRange,
        marginParticipants,
        currency,
        marginTax,
        dateOverride,
        tableView
    }) => ({
        organization: { id, modelId: "organization/organization" },
        dateRanges,
        daysCount,
        participantsRange,
        marginParticipants,
        currency,
        marginTax,
        dateOverride,
        tableView
    })
);
const selectChoices = createSelector(selectAllChoices, (choices) =>
    choices.map(
        ({ id, occupancyType, override, overrideNoFlights, childPrices }) => ({
            id,
            occupancyType,
            override,
            overrideNoFlights,
            childPrices,
        })
    )
);
const selectChoiceParams = createSelector(
    selectChoices,
    selectChoicesPricesColumns,
    selectAllChoicesPrices,
    (choices, columns, choicesPrices) =>
        choices.map(
            ({
                id,
                occupancyType,
                override,
                overrideNoFlights,
                childPrices = [],
            }) => {
                let overrideData = {};

                for (const [choiceId, groupId, paxId] of columns.filter(
                    ([choiceId]) => id === choiceId
                )) {
                    const id = makeId(choiceId, groupId, paxId);
                    const { retailPrice, manualFactor } = choicesPrices.find(
                        (cp) => cp.id === id
                    );
                    const value =
                        retailPrice || null === retailPrice
                            ? { retailPrice }
                            : manualFactor || null === manualFactor
                            ? { manualFactor }
                            : undefined;

                    if (value) {
                        overrideData = {
                            ...overrideData,
                            [groupId]: !paxId
                                ? value
                                : {
                                      ...overrideData[groupId],
                                      [paxId]: value,
                                  },
                        };
                    }
                }

                return {
                    id,
                    occupancyType,
                    override,
                    overrideNoFlights,
                    overrideData,
                    childPrices: childPrices.map(([id]) => ({ id })),
                };
            }
        )
);
export const selectDatesParams = createSelector(
    (state) => selectAllDates(state),
    (dates) => dates.map(({ date }) => date)
);
const selectCalculationDetailsParams = createSelector(
    [
        selectEntriesParam,
        selectFieldParams,
        selectChoiceParams,
        selectDatesParams,
    ],
    (entries, fields, occupancyChoices, dates) => {
        const fieldsView = { ...fields };

        delete fieldsView.tableView;

        const params = { entries, occupancyChoices, dates, ...fieldsView };
        processFlightOverride(params);
        return params;
    }
);



export const useCalculationDetails = () => {
    return useCalculationDetailsQuery(
        useSelector(selectCalculationDetailsParams),
        {
            skip: !useManualReload(),
        }
    );

};
