import {createContext} from "react";
import {Container} from "aurelia-framework";
import {NumberValueConverter, PercentageValueConverter} from "../converters";
import {CurrencyValueConverter} from "../currency/currency-value-converter";
import {DialogService} from "aurelia-dialog";
import {I18N} from "aurelia-i18n";
import {UserClient} from "../api/user-client";

const container = Container.instance;

/**
 * Exports some useful Aurelia services to be accessible using a React context.
 *
 * @type {React.Context<{
 *     currencyValueConverter: CurrencyValueConverter,
 *     dialogService: DialogService,
 *     i18n: I18N,
 *     numberValueConverter: NumberValueConverter,
 *     percentageValueConverter: PercentageValueConverter,
 *     userClient: UserClient
 * }>}
 */
const AureliaContext = createContext({
    currencyValueConverter: container.get(CurrencyValueConverter),
    dialogService: container.get(DialogService),
    i18n: container.get(I18N),
    numberValueConverter: container.get(NumberValueConverter),
    percentageValueConverter: container.get(PercentageValueConverter),
    userClient: container.get(UserClient),
});

export default AureliaContext;
