import React, { useState } from 'react';
import { Grid, Row, Col, DatePicker } from 'rsuite';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { setField, useField } from '../store/fields-slice';
import Help from './help';
import { updateManualReload } from '../store/state-slice';
import { FlashService } from '../../../flash/flash-service';
import { Container } from 'aurelia-dependency-injection';

const DurationCollection = ({
    label,
    help,
    field,
    currency,
    column,
    required,
    i18n,
    entries,
    type,
    defaultValue,
    disabled,
    ...props
}) => {
    const durations = useField(field);
    const dispatch = useDispatch();
    const [localDuration, setLocalDuration] = useState(durations);

    const dispatchDuration = (duration) => {
        let isDispatchAble = true;
        let revertLocal = false;

        duration.forEach((item) => {
            if (moment(item.toDate).diff(moment(item.fromDate), 'days') < 0) {
                isDispatchAble = false;
                revertLocal = true;
                Container.instance
                    .get(FlashService)
                    .error('Bis-Datum sollte nicht kleiner sein als Von-Datum');
            }

            if (
                moment(item.fromDate).year() < 1900 ||
                moment(item.toDate).year() < 1900
            ) {
                isDispatchAble = false;
                revertLocal = true;
                Container.instance
                    .get(FlashService)
                    .error('Ungültiges Eingabedatum');
            }

            if (item.fromDate == '' || item.toDate == '') {
                isDispatchAble = false;
                revertLocal = false;
            }
        });
        if (isDispatchAble) {
            dispatch(setField(field, duration));
            dispatch(updateManualReload(true));
        } else {
            if (revertLocal) {
                setLocalDuration(durations);
            }
        }
    };
    const onChange = (val, index, valType) => {
        val = moment(val).format('YYYY-MM-DD');
        const newDuration = [...localDuration].map((item, i) => {
            if (i == index) {
                return { ...item, [valType]: val };
            }
            return item;
        });

        setLocalDuration(newDuration);
        dispatchDuration(newDuration);
    };

    const onDelete = (index) => {
        const newDuration = [...localDuration].filter((item, i) => i != index);
        setLocalDuration(newDuration);
        dispatchDuration(newDuration);
    };

    const addNewItem = () => {
        const newDuration = [
            ...localDuration,
            {
                fromDate: '',
                toDate: '',
            },
        ];
        setLocalDuration(newDuration);
    };

    const InputDatePicker = ({ item, index, dateType }) => {
        return (
            <DatePicker
                id={field + dateType + item?.id}
                format="dd.MM.yyyy"
                name={field}
                className="sio-date-input"
                type={'date'}
                value={item[dateType] ? new Date(item[dateType]) : null}
                onChange={(date) => {
                    onChange(date, index, dateType);
                }}
                placeholder={'DD.MM.YYYY'}
                disabled={disabled}
                cleanable={false}
                size="sm"
            />
        );
    };
    return (
        <div className="col-xs-6 col-sm-4 col-md-6 col-lg-4 form-group">
            <div>
                <label htmlFor={field} className={required ? 'required' : null}>
                    {'Gültigkeit'}
                </label>
                {help && (
                    <>
                        {' '}
                        <Help>{i18n.tr(help)}</Help>
                    </>
                )}
            </div>
            <Grid fluid>
                {localDuration?.length > 0 &&
                    localDuration.map((item, index) => {
                        return (
                            <Row
                                key={index}
                                gutter={16}
                                style={{ marginBottom: '6px' }}
                            >
                                <Col xs={11}>
                                    <Row gutter={6}>
                                        <Col sm={24} md={4}>
                                            <label style={{ marginTop: '5px' }}>
                                                {' von'}
                                            </label>
                                        </Col>
                                        <Col sm={24} md={20}>
                                            <InputDatePicker
                                                item={item}
                                                index={index}
                                                dateType="fromDate"
                                            />
                                        </Col>
                                    </Row>
                                </Col>

                                <Col xs={11}>
                                    <Row gutter={10}>
                                        <Col sm={24} md={4}>
                                            <label style={{ marginTop: '5px' }}>
                                                {' bis'}
                                            </label>
                                        </Col>
                                        <Col sm={24} md={20}>
                                            <InputDatePicker
                                                item={item}
                                                index={index}
                                                dateType="toDate"
                                            />
                                        </Col>
                                    </Row>
                                </Col>

                                <Col xs={2}>
                                    {localDuration.length > 1 && (
                                        <button onClick={() => onDelete(index)}>
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    )}
                                </Col>
                            </Row>
                        );
                    })}

                <Row>
                    <div role="group" className="rs-btn-group">
                        <button
                            type="button"
                            className="rs-btn rs-btn-default rs-btn-icon rs-btn-icon-placement-left rs-btn-icon-with-text rs-btn-xs rs-btn-green"
                            onClick={addNewItem}
                        >
                            <i className="rs-icon sio-icon-padding-fix fa fa-plus"></i>
                            weiterer Zeitraum
                            <span className="rs-ripple-pond">
                                <span className="rs-ripple"></span>
                            </span>
                        </button>
                    </div>
                </Row>
            </Grid>
        </div>
    );
};

export default DurationCollection;
